const defaultOptions = () => ({
  headings: {
  },
  columnsClasses: {
    description: 'td-description',
  },
  sortIcon: {
    base: "fa fas",
    is: "fa-sort",
    up: "fa-sort-up",
    down: "fa-sort-down",
  },
  debounce: 250,
  perPage: 50,
  perPageValues: [],
  orderBy: {
  },
  dateColumns: [
    'date',
    'paymentDueDate',
  ],
  dateFormat: "MMMM DD, YYYY",
  pagination: {
    chunk: 10,
  },
  sortable: [],
  // filterable: [],
  texts: {
    filter: "Search table",
    filterPlaceholder: "Search",
  },
});

export const options = function (options) {
  return Object.assign(defaultOptions(), options);
};
