<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <payments-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <h2>Payments in process</h2>
                <p>View or download payments in process. Once a payment is posted, the payment will be made within 8 to 16 days.</p>

                <p>
                  <a
                    class="link-text"
                    @click.prevent="triggerModal"
                  >
                    Click here for more information about status categories
                  </a>
                  <modal
                    ref="categoriesModal"
                    name="categoriesModal"
                    class="is-medium"
                    :settings="modalSettings"
                  >
                    <h4>Controller hold</h4>
                    <p>May be placed when the documentation does not comply with the contract, appropriation law, or other principles or regulations. Call the Pre-Audit Verification Unit at <a href="tel:+2156863833">(215) 686-3833</a> or <a href="tel:+2156866679">(215) 686-6679</a> to discuss the hold.</p>
                    <h4>Revenue tax hold</h4>
                    <p>Placed when there are taxes due to the City or the vendor is not registered to pay business taxes. Call the Tax Clearance Unit at <a href="tel:+2156866565">(215) 686-6565</a> or email <a href="mailto:Tax.Clearance@phila.gov">Tax.Clearance@phila.gov</a> to discuss the hold.</p>
                    <h4>In progress</h4>
                    <p>Contact the department issuing the payment for more details. For all other questions, reach out to your <router-link to="/departments/department-finance">City contact.</router-link></p>
                  </modal>
                </p>

                <div :class="{ 'hide-edges': hideEdges }">
                  <v-client-table
                    id="my-table"
                    ref="payments"
                    :columns="tableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                    class="is-align-middle"
                  >
                    <template slot="afterLimit">
                      <download-buttons
                        @clicked="downloadFile"
                      />
                    </template>
                    <template
                      slot="amount"
                      slot-scope="props"
                    >
                      ${{ $helpers.formatMoney(props.row.amount) }}
                    </template>
                    <template
                      slot="postedDate"
                      slot-scope="props"
                    >
                      {{ props.row.postedDate }}
                    </template>
                  </v-client-table>
                </div>
                <div>
                  <p>
                    The invoice number is contained in the description field, between either asterisks or pound signs. For example, *12345* or #12345#.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsTabs from "@/components/PaymentsTabs";
import DownloadButtons from "@/components/DownloadButtons";
import Modal from "@/components/common/Modal";

import { options } from "@/config/tableOptions";
const tableColumns = [
  "amount",
  "description",
  "purchaseOrderNum",
  "voucherNum",
  "postedDate",
  "status",
  "departmentName",
];

export default {
  components: {
    PaymentsTabs,
    DownloadButtons,
    Modal,
  },
  data() {
    return {
      modalSettings: {
        title: 'Status Categories',
      },
      tableColumns,
      tableOptions: options({
        headings: {
          purchaseOrderNum: "Purchase\norder #",
          voucherNum: "Payment\nVoucher #",
          postedDate: "Posted\ndate",
          departmentName: "Department",
        },
        orderBy: {
          ascending: true,
          column: "postedDate",
        },
        dateFormat: 'MM/DD/YYYY',
        sortable: tableColumns,
        columnsClasses: {
          amount: 'amount',
        },
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      return this.$store.state.vendors.pendingPayments || [];
    },
  },
  async fetch({ store, params }) {
    let vendor = params.id;
    if (params.id === 'own') {
      vendor = null;
    }

    await store.dispatch('vendors/getPendingPayments', vendor);
    return Promise.resolve();
  },
  methods: {
    triggerModal() {
      console.log('triggerModal is running, this.$refs', this.$refs);
      this.$refs.categoriesModal.show();
    },
    downloadFile(type) {
      const fileName = `Pending-Payments-${this.$dayjs().format('YYYY-MM-DD-HH-mm')}`;

      /**
       * Prepare Object to Download
       */
      let data = this.tableValues.map(payment => [
        this.$helpers.formatMoney(String(payment.amount)),
        String(payment.description),
        String(payment.purchaseOrderNum),
        String(payment.voucherNum),
        String(payment.postedDate),
        String(payment.status),
        String(payment.departmentName),
      ]);

      if (data.length === 0) {
        this.$warning(`There is not data to export...`);
        return;
      }

      data.unshift([
        "Amount",
        "Description",
        "Purchase\nOrder #",
        "Payment\nVoucher #",
        "Payment\nDue Date",
        "Status",
        "Department",
      ]);

      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          'Pending Payments',
          {
            0: { cellWidth: 'wrap' },
            2: { cellWidth: 'wrap' },
            3: { cellWidth: 'wrap' },
            5: { cellWidth: 'wrap' },
          },
          {
            cellWidth: 'wrap',
          },
        );
      }

      data = null;
    },
  },
};
</script>

<style>

.link-text {
  text-decoration: underline;
}

</style>
